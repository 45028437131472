import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Welcome to Pigeon! Pigeon is a messaging tool designed to help you communicate with your patients quickly and easily.`}</p>
    <p>{`To set up your account with Pigeon you will need the administrator for your provider to send you an invite. This invite will include a link to download the Pigeon app, or you can search “Pigeon Health” in the app store.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/invite.png",
        "alt": "invite"
      }}></img></p>
    <p>{`Once you open the Pigeon app click “Register now” at the bottom of the screen. Use the email address or phone number that your administrator used to setup your account.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/welcome.png",
        "alt": "welcome"
      }}></img></p>
    <p>{`You will be sent a code to verify your identity at the email address or phone number associated with your account. Enter this code to proceed.`}</p>
    <p>{`Once you have verified your identity, you can finish setting up your account by adding a password and a pin.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/pin.png",
        "alt": "pin"
      }}></img></p>
    <p>{`The password will be used to login to your account on the phone or in the web app. Passwords must be at least 10 characters and contain a capital letter, number, and special character OR be longer than 16 characters (this is called a passphrase).`}</p>
    <p>{`The pin is used to unlock the app on your phone when you’re not using it.`}</p>
    <p>{`Consult your office administrator for a list of best practices using Pigeon. Different offices may have different rules.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      