import React from 'react';
import Typography from "../common/Typography";
import {Col, Container, Row} from "react-bootstrap";

const renderFeatures = (items = []) => {
    return items.map((item, i) => renderFeature(item, i))
}

const renderFeature = (item, index) => {
    return (
        <Row key={`feat-${index}`} className="d-flex align-items-center">
            <Col md={index % 2 ? 5 : 6 } className={index % 2 ? "mb-5" : "mb-5 order-md-1"}>
                <div className="px-md-1">
                    <img className="img-fluid" src={item.image} alt={item.title}/>
                </div>
            </Col>
            <Col md={index % 2 ? 7 : 6 } className={index % 2 ? "mb-5" : "mb-5 order-md-0"}>
                <article className="px-md-1">
                    <Typography component="h2" className="mb-3">{item.title}</Typography>
                    <Typography component="p">{item.description}</Typography>
                    <hr className="d-md-none my-5"/>
                </article>
            </Col>
        </Row>
    )
}

const Features = ({items = []}) => {
    return (
        <section id="features" className="section">
            <Container className="pt-5 pt-md-0 mt-5 mt-md-0">
                <Typography component="h1" className="text-center">Product Features</Typography>
            </Container>
            <Container>
                {renderFeatures(items)}
            </Container>
        </section>
    )
}

export default Features