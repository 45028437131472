import React from 'react';
import FaqItem from "./FaqItem";
import {Col, Container, Row} from "react-bootstrap";
import Typography from "../common/Typography";
import GettingStarted from "../../content/faq/getting-started.mdx";
import Messaging from "../../content/faq/messaging.mdx";
import SettingUpTeams from "../../content/faq/setting-up-teams.mdx";
import AddingStaff from "../../content/faq/adding-staff.mdx";
import AddingPatients from "../../content/faq/adding-patients.mdx";
import AddingAppointments from "../../content/faq/adding-appointments.mdx";

const Faq = () => {
    return (
        <section id="faq">
            <div className="wrapper">
                <div className="grid"/>
                <div className="grid"/>
                <div className="grid"/>
                <div className="grid"/>
                <div className="sizer"/>
                <div className="section">
                    <div className="content">
                        <Container className="text-center">
                            <Typography component="h1">Frequently Asked Questions</Typography>
                            <Typography component="p" className="lead mb-5 font-weight-bold">Check the list of topics below to learn more about using the Pigeon
                                platform.</Typography>
                        </Container>
                        <Container>
                            <Row>
                                <Col md={{span: 10, offset: 1}} lg={{ span: 10, offset: 1}}>
                                    <div className="faq-container">
                                        <FaqItem question="Getting Started" answer={<GettingStarted />}/>
                                        <FaqItem question="Messaging" answer={<Messaging />}/>
                                        <FaqItem question="Setting up teams" answer={<SettingUpTeams />}/>
                                        <FaqItem question="Adding Staff" answer={<AddingStaff />}/>
                                        <FaqItem question="Adding Patients" answer={<AddingPatients />}/>
                                        <FaqItem question="Adding Appointments" answer={<AddingAppointments />}/>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Faq