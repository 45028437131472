import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Teams are used to organize patients and staff members to fit your workflow.`}</p>
    <p>{`Each team has a unique phone number. When a patient sends a text to that phone number, or a message to that team in the app, then that message will go to every staff member on that team.`}</p>
    <p>{`For example, your office might have a scheduling team and a care team. If a patient texted your scheduling team, then that message would go to the receptionists and not the doctors.`}</p>
    <p>{`This way, staff members on the care team wouldn’t be inundated with messages about scheduling and vice versa.`}</p>
    <p>{`To set up your teams log in to the Pigeon web app and go to the"Teams" tab.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/teams.png",
        "alt": "teams"
      }}></img></p>
    <p>{`Note: only admins have the ability to edit teams and create new teams. Whoever set up your account is an admin. They have the ability to make other staff members admins as well.`}</p>
    <p>{`To add a new team, click "Add team." Your team will need a name and an area code. Once it is created it will be randomly assigned a phone number with that area code.`}</p>
    <p>{`Once you have created your team you can select it, and then add staff members to that team.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      