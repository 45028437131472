import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import Typography from "../common/Typography";
import openCalendlyWidget from "../../utils/openCalendlyWidget";

const FooterCta = () => {
    return (
        <section id="on-boarding" className="text-white">
            <div className="section">
                <div className="container text-center">
                    <Row >
                        <Col md={{span: 8, offset: 2}} >
                            <Typography component="p">Your office can get set up and start on-boarding patients in just a few hours. Fill out our provider to start improving your office's patient communication today.</Typography>
                        </Col>
                    </Row>
                    <div className="cta-wrapper">
                        <Button onClick={openCalendlyWidget} type="primary" size="lg" className="rounded-pill btn-cta">Get Started</Button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default FooterCta