import React, {useEffect} from 'react';
import {Button, Container} from "react-bootstrap";
import Typography from "../common/Typography";
import openCalendlyWidget from "../../utils/openCalendlyWidget";

const Hero = () => {
    useEffect(() => {
        setTimeout(() => {
            window.initAnimation();
            // setLoading(false);
        }, 0)
        // window.initAnimation();
    }, [])
    return (
        <section id="hero" className="section">
            <div className="animation-loader"></div>
            <div id="animation_container" >
                <canvas id="canvas" />
                <div id="dom_overlay_container" style={{pointerEvents: 'none', overflow: 'hidden', width: '0', height: '1080px', position: 'absolute', left: '0px', top: '0px', display: 'block'}}>
                </div>
            </div>

            <div className="content text-white hero-text">
                <div className="line-bg"></div>
                <Container className="my-5">
                    <Typography component="p" className="lead text-center">The HIPAA Compliant Messaging and Telehealth Platform<br/> for Increasing Patient Satisfaction and Staff Productivity!</Typography>
                    <div className="cta-wrapper d-flex">
                        <Button onClick={openCalendlyWidget} type="primary" size="lg" className="mx-auto rounded-pill btn-cta">Schedule a free demo</Button>
                    </div>
                </Container>
            </div>
            {/*<div className="sizer"/>*/}

        </section>
    )
}

export default Hero