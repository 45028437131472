import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To add a patient, click the “Patients” link in the sidebar. On the “Patients” page click the “Add Patient” button.`}</p>
    <p>{`Every patient has to have a name, email address AND/OR phone number, and birthdate. You must also select whether the patient has signed the consent form yet and one team for the patient to be on (you can add them to multiple teams later).`}</p>
    <p>{`The email address and phone number determine whether the patient can access the patient app or can respond through SMS. Add an email address to let the patient log in to the patient app, and a phone number to communicate via SMS.`}</p>
    <p>{`SMS is easier for most patients to use, but carries higher security risks as SMS messages cannot be encrypted outside of Pigeon.`}</p>
    <p>{`When a patient is first added to the system they will show up in the Messages screen as unconfirmed. To avoid sending PHI to the wrong number, a patient must confirm their identity using their birthdate.`}</p>
    <p>{`All patients have to go through the confirmation process.`}</p>
    <p>{`Once a patient is confirmed, if they have not signed a waiver they will be prompted to sign one electronically.`}</p>
    <p>{`The Pigeon system will not prevent staff from communicating with patients when they have not signed a waiver. However, there will be a red bar present indicating they have not signed. Be very careful about transmitting any PHI without a signed waiver.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      