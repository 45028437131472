import React from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import Icon from '@mdi/react';
import {mdiEmail} from '@mdi/js'
import Typography from "../common/Typography";

const Contact = () => {
    return (
        <section id="contact">
            <div className="wrapper">
                <div className="grid"/>
                <div className="grid"/>
                <div className="grid"/>
                <div className="grid"/>
                <div className="section pt-0">
                    <Container >
                        <Typography component="h1" className="text-center">Can’t find your answer?</Typography>
                        <Row>
                            <Col lg={10} md={10} className="offset-lg-1 offset-md-1">
                                <Row className="d-flex align-items-center">
                                    <Col lg={8} md={6}>
                                        <Typography component="p" className="text-md-right text-center">
                                            Do you have more questions or cant find exactly what you searching for?
                                            Contact us! we will be happy to help you to find answers
                                        </Typography>
                                    </Col>
                                    <Col lg={4} md={6} className="text-center">
                                        <Button variant="secondary" size="lg" className="rounded-pill btn-cta" href="mailto:apro@pigeonhealth.com">
                                            <Icon size={1} path={mdiEmail} color="white" className="mr-2"/>
                                            <span>Contact Us</span>
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </section>
    )
}

export default Contact