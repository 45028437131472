import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import Typography from "../common/Typography";
import openCalendlyWidget from "../../utils/openCalendlyWidget";

const renderCta = () => {
    return (
        <div className="py-5 text-center">
            <div className="col-md-8 offset-md-2">
                <Typography component="p" className="font-weight-bold mb-5">Your office can get set up and start on-boarding patients in just a
                    few hours. Fill out
                    our provider to start improving your office's patient communication today.</Typography>
                <Button onClick={openCalendlyWidget} variant="primary" size="lg" className="rounded-pill btn-cta">Get Started</Button>
            </div>
        </div>
    )
}

const renderItems = (items = []) => {
    return (
        <Row className="d-flex align-items-stretch">
            {items.map((item, i) => renderItem(item, i))}
        </Row>
    )
}

const renderItem = ({title = '', description = '', image = null}, index) => {
    return (
        <Col key={index} md={4} className="mb-5">
            <div className="benefit">
                <Typography component="h3" className="h5 mb-3">{title}</Typography>
                <div className="px-md-4">
                    <div className="mb-3">
                        {image && <img src={image} alt={title} width={140} height={140}/>}
                    </div>
                    <Typography component="p">{description}</Typography>
                </div>
            </div>
        </Col>
    )
}

const Benefits = ({items = []}) => {
    return (
        <section id="benefits" className="section">
            <div className="wrapper">
                <div className="grid"/><div className="grid"/><div className="grid"/><div className="grid"/>

                <div className="sizer"/>
                <div className="content">

                    <div className="container">
                        <Typography component="h1" className="text-center">Benefits</Typography>
                        <div>{renderItems(items)}</div>
                        <div>{renderCta()}</div>
                    </div>
                </div>
                <div className="sizer"/>
            </div>
        </section>
    )
}

export default Benefits