import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Adding an appointment will schedule appointment reminders for the patient. Reminders will be sent automatically when the appointment is added as well as one week and one day before the scheduled appointment.`}</p>
    <p>{`To schedule an appointment go to the appointments screen and click the “Add Appointment” button. Select a patient, use the calendar to pick a date and time, select a team and then click save.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/appointments.png",
        "alt": "appointments"
      }}></img></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      