import React from 'react';
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, { Pagination, Autoplay, Navigation } from 'swiper';

import 'swiper/swiper.scss';
import Typography from "../common/Typography";

// install Swiper modules
SwiperCore.use([Pagination, Autoplay, Navigation]);

const swiperConfig = {

    spaceBetween: 45,
    slidesPerView: 1,
    // autoplay: {
    //     delay: 3000
    // },
    // pagination: {
    //     clickable: true
    // },
    centerInsufficientSlides: true,
    breakpoints: {
        // when window width is >= X
        320: {
            slidesPerView: 1,
            spaceBetween: 20
        },
        576: {
            slidesPerView: 1,
            spaceBetween: 45
        },
        768: {
            // slidesOffsetBefore: 50,
            // slidesOffsetAfter: 50,
            slidesPerView: 3,
            spaceBetween: 45
        },
        1920: {
            slidesPerView: 4,
            spaceBetween: 45
        }
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
}

const renderTestimonials = (items = []) => {
    return (
        <Swiper  {...swiperConfig}>
            {items.map((item, i) => renderSlide(item, i))}
        </Swiper>
    )
}

const renderSlide = ({name = '', title = '', description = '', image = null}, index) => {
    return (
        <SwiperSlide key={index}>
            <div className="text-center testimonial">
                <div className="testimonial-card mb-4">
                    <div className="testimonial-avatar">
                        {image && <img src={image} alt={name} width={180} height={180} className="rounded-circle"/>}
                    </div>
                    <div className="testimonial-body">
                        <Typography component="h3" className="h5 text-body mb-4">{name}</Typography>
                        <Typography component="p" className="mb-4">{title}</Typography>
                        <div className="w-50 mx-auto mb-4">
                            <hr/>
                        </div>
                        <blockquote>
                            <Typography component="p" className="font-italic">{description}</Typography>
                        </blockquote>
                    </div>
                </div>
            </div>
        </SwiperSlide>
    )
}

const Testimonials = ({items = []}) => {
    return (
        <section id="testimonials" className="section">
            <div className="container">
                <Typography component="h1" className="text-center">Testimonials</Typography>
            </div>
            <div className="testimonial-container container position-relative">
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                {renderTestimonials(items)}
            </div>
        </section>
    )
}

export default Testimonials