import React, {useState} from "react";
import Typography from "../common/Typography";
import {MDXProvider} from "@mdx-js/react";
const FaqItem = ({question = '', answer = ''}) => {
    const [expanded, setExpanded] = useState(false)
    return (
        <article className="faq-item">
            <header role="button" tabIndex="-1"
                    className={expanded ? "faq-item-q active" : "faq-item-q"}
                    onClick={() => setExpanded(!expanded)}
                    onKeyUp={() => setExpanded(!expanded)}
            >
                <Typography component="h3" className="h5">{question}</Typography>
            </header>
            <div className={expanded ? "faq-item-a active" : "faq-item-a"}>
                <div className="p-4"><Typography component="p">
                    <MDXProvider>
                        {answer}
                    </MDXProvider>
                </Typography></div>

            </div>
        </article>
    )
}

export default FaqItem