import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`You’ll spend most of your time in Pigeon using the messaging tab. Messaging in Pigeon works almost exactly like sending text messages on your phone. Just select the patient you want to message, type your message and hit send.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/conversation.png",
        "alt": "conversation"
      }}></img></p>
    <p>{`You can also add pictures to a message.`}</p>
    <p>{`In Pigeon, you’ll be able to see all of the patients on your team. Teams can be used to organize patients and staff members however you would like.`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/messages.png",
        "alt": "messages"
      }}></img></p>
    <p>{`For example, if your office has receptionists who handle all of the scheduling and back of office staff who handle all of the care, then you could have two teams. One team would be for scheduling, and one would be for care.`}</p>
    <p>{`Each team has a unique phone number. When a patient sends a text to that phone number, or a message to that team in the app, then that message will go to every staff member on that team. For example, if a patient texted your scheduling team, then that message would go to the receptionists and not the doctors.`}</p>
    <p>{`This way, staff members on the care team wouldn’t be inundated with messages about scheduling and vice versa.`}</p>
    <p>{`You can choose whether to receive notifications for a patient by joining or leaving the conversation. By default, every staff member on the team joins the conversation when the patient is added to Pigeon.`}</p>
    <p>{`At any point, you can ping another staff member from within a conversation, and they will receive a notification encouraging them to check the conversation. This way you can assign someone on your staff to read new messages and alert the appropriate staff members.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      