import * as React from "react"
import {useEffect} from "react"
import {useSelector} from 'react-redux';
import MainLayout from "../layouts/MainLayout";
import Hero from "../components/frontpage/Hero";
import Features from "../components/frontpage/Features";
import Benefits from "../components/frontpage/Benefits";
import Testimonials from "../components/frontpage/Testimonials";
import Faq from "../components/frontpage/Faq";
import Contact from "../components/frontpage/Contact";
import FooterCta from "../components/frontpage/FooterCta";

// markup
const IndexPage = () => {
    const features = useSelector(state => state.frontpage.features)
    const benefits = useSelector(state => state.frontpage.benefits)
    const testimonials = useSelector(state => state.frontpage.testimonials)

    useEffect(() => {
        // ...

    }, [])

    return (
        <MainLayout>
            <main>
                <Hero/>
                <Features items={features}/>
                <Benefits items={benefits}/>
                <Testimonials items={testimonials}/>
                <Faq />
                <Contact/>
                <FooterCta/>
            </main>
        </MainLayout>
    )
}

export default IndexPage
