import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`To add a staff member login to the Pigeon web app and navigate to the staff tab. Click “Add Staff.”`}</p>
    <p><img parentName="p" {...{
        "src": "/faq/staff.png",
        "alt": "teams"
      }}></img></p>
    <p>{`To add a staff member you will need a name, email address and optional phone number, a role and they have to be on at least one team.`}</p>
    <p>{`A mobile phone number is optional, but if one is added to a staff member’s account they can use it to register their account or reset their password. After you add a phone number to a staff member they’ll receive a text asking them to confirm their identity by replying with their last name. Their phone number will show up as unconfirmed and they won’t be able to use it until they reply with their last name.`}</p>
    <p>{`You can add staff to as many teams as you’d like, but they have to be on at least one.`}</p>
    <p>{`Roles are used to determine who can perform which functions. Here is a table of the different roles and their permissions:`}</p>
    <p><strong parentName="p">{`Admin, Doctors`}</strong>{` - Add teams and staff, as well as the permissions below\\
`}<strong parentName="p">{`Schedulers`}</strong>{` - Add patients and appointments, as well as the permissions below\\
`}<strong parentName="p">{`Medical Assistant`}</strong>{`, Nurse Practitioner, Nurse - Send and receive messages`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      